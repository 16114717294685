<template>
  <v-form
    ref="form"
    :readonly="isEdit"
  >
    <v-card>
      <v-card-actions class="d-flex justify-center">
        <div>
          <v-btn
            v-if="isRemake"
            :loading="loadingButtonSignAgain"
            color="warning"
            class="mt-1 me-3"
            @click="signAgainTermLgpd()"
          >
            <v-icon>
              {{ icons.mdiDraw }}
            </v-icon>
            <span>Refazer</span>
          </v-btn>
          <v-btn
            v-if="sendContract"
            :loading="loadingButtonSendSigned"
            color="secondary"
            class="mt-1 me-3"
            @click="sendingSignedDoctoClient()"
          >
            <v-icon>
              {{ icons.mdiFileSendOutline }}
            </v-icon>
            <span>2º Via</span>
          </v-btn>
        </div>
      </v-card-actions>
      <v-card-text>
        <v-card
          outlined
          class="mb-3"
        >
          <v-card-title class="mb-2">
            Dados do Cliente
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.client_name"
                  outlined
                  dense
                  label="Nome"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.cpf_cnpj"
                  outlined
                  dense
                  label="CPF/CNPJ"
                />
              </v-col>
              <!-- <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.email"
                  outlined
                  dense
                  label="Email"
                />
              </v-col> -->
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.client_phone"
                  outlined
                  dense
                  label="Telefone"
                />
              </v-col>
              <v-col
                v-if="isEdit"
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  disabled
                  label="Assinatura"
                  class="pt-0"
                  hide-details
                ></v-text-field>
                <v-img
                  :src="termLgpdItem.customer_subscription"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mb-3"
        >
          <v-card-title class="mb-2">
            Dados da Proposta
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.proposal_number"
                  outlined
                  dense
                  label="Proposta"
                />
              </v-col>
              <!-- <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.receipt_number"
                  outlined
                  dense
                  label="Recibo"
                />
              </v-col> -->
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="termLgpdItem.contract_date"
                  outlined
                  dense
                  type="date"
                  label="Data da Venda"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="py-0"
              >
                <v-autocomplete
                  v-model="idVehicle"
                  :items="listVehiclesItems[0]"
                  item-text="vehicle_model"
                  item-value="id"
                  outlined
                  dense
                  label="Modelo"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="3"
                class="py-0"
              >
                <v-switch
                  v-model="termLgpdItem.bid_fixed"
                  label="Lance Fixo"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="3"
                class="py-0"
              >
                <v-switch
                  v-model="termLgpdItem.life_insurance"
                  label="Seguro"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined>
          <v-card-title class="mb-2">
            Dados do Vendedor
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="userData.name"
                  outlined
                  readonly
                  dense
                  label="Nome"
                />
              </v-col>
              <v-col
                cols="12"
                lg="6"
                class="py-0"
              >
                <v-text-field
                  v-model="userData.phone"
                  outlined
                  readonly
                  dense
                  label="Telefone"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <div>
          <v-btn
            v-if="!isEdit"
            :loading="loadingButtonAdd"
            color="success"
            class="mt-1 me-3"
            @click="addTermLgpd()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Incluir</span>
          </v-btn>
          <v-btn
            v-if="!isEdit"
            color="error"
            class="mt-1 me-3"
            @click="clearModal()"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            v-if="isValid"
            :loading="loadingButtonValid"
            color="info"
            class="mt-1 me-3"
            @click="validateTermLgpd()"
          >
            <v-icon>
              {{ icons.mdiCheckDecagram }}
            </v-icon>
            <span>Validar</span>
          </v-btn>
          <v-btn
            v-if="isDisable"
            :loading="loadingButtonDisable"
            color="error"
            class="mt-1 me-3"
            @click="disabledTermLgpd()"
          >
            <v-icon>
              {{ icons.mdiCancel }}
            </v-icon>
            <span>Inutilizar</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCancel, mdiCheckDecagram, mdiClose, mdiDraw, mdiFileSendOutline, mdiPlus,
} from '@mdi/js'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },

    isValid: {
      type: Boolean,
      default: false,
    },

    isDisable: {
      type: Boolean,
      default: false,
    },

    isRemake: {
      type: Boolean,
      default: false,
    },

    sendContract: {
      type: Boolean,
      default: false,
    },

    termLgpdItem: {
      type: Object,
      default: () => ({
        term_status: '',
      }),
    },

    closeModal: {
      type: Function,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    updatedTable: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    loading2: '',
    icons: {
      mdiPlus,
      mdiCancel,
      mdiCheckDecagram,
      mdiDraw,
      mdiFileSendOutline,
      mdiClose,
    },
    loadingButtonAdd: false,
    loadingButtonValid: false,
    loadingButtonDisable: false,
    loadingButtonSignAgain: false,
    loadingButtonSendSigned: false,
    listVehiclesItems: [],
    idVehicle: [],
    dataVehicle: {
      name: '',
      id: '',
    },

  }),

  created() {
    this.getItemsVehicles()
  },

  methods: {
    async sendingSignedDoctoClient() {
      this.loadingButtonSendSigned = true
      const data = {
        id: this.termLgpdItem.id,
      }
      await axiosIns
        .post('/api/v1/integration/lgpd_term/sending_signed_docto_client', data)
        .then(res => {
          this.closeModal()
          this.$swal({
            icon: 'success',
            title: 'Termo enviado.',
            text: `${res.data.text}`,
            showConfirmButton: false,
            timer: 8000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar termo!',
            showConfirmButton: false,
            text: error,
            timer: 5000,
          })
        })
        .finally(() => {
          this.loadingButtonSendSigned = false
        })
    },
    async getItemsVehicles() {
      await axiosIns.get('api/v1/integration/vehicle_model/index')
        .then(res => {
          this.listVehiclesItems.push(res.data.data)
        })
    },

    async storeTermLgpd() {
      if (this.$refs.form.validate()) {
        this.loadingButtonAdd = true
        this.termLgpdItem.term_status = 'Aberta'

        await axiosIns
          .post('/api/v1/integration/lgpd_term/store/', this.termLgpdItem)
          .then(() => {
            this.closeModal()
            this.initialize()
            this.$swal({
              icon: 'success',
              title: 'Termo incluído.',
              text: 'Operação realizada com sucesso!',
              showConfirmButton: false,
              timer: 3000,
            })
          })
          .catch(error => {
            this.closeModal()
            this.initialize()
            this.$swal({
              icon: 'error',
              title: 'Erro ao inserir termo!',
              showConfirmButton: false,
              text: error,
              timer: 3000,
            })
          })
          .finally(() => {
            this.loadingButtonAdd = false
          })
      }
    },

    async addTermLgpd() {
      this.termLgpdItem.term_status = 'Aberta'
      const body = {
        proposal_number: this.termLgpdItem.proposal_number,

        // receipt_number: this.termLgpdItem.receipt_number,
        client_name: this.termLgpdItem.client_name,

        cpf_cnpj: this.termLgpdItem.cpf_cnpj,
        client_phone: this.termLgpdItem.client_phone,
        contract_date: this.termLgpdItem.contract_date,
        bid_fixed: this.termLgpdItem.bid_fixed,
        life_insurance: this.termLgpdItem.life_insurance,
        customer_subscription: this.termLgpdItem.customer_subscription,
        submit: this.termLgpdItem.submit,

        // email: this.termLgpdItem.email,
        vendor_name: this.userData.name,
        conferred_by: this.termLgpdItem.conferred_by,
        vehicle_models_id: this.idVehicle,
        observation: this.termLgpdItem.observation,
        term_refused: this.termLgpdItem.term_refused,
        code_validity_date: this.termLgpdItem.code_validity_date,
        term_revalidated: this.termLgpdItem.term_revalidated,
        validated_date: this.termLgpdItem.validated_date,
        vendor_cpf: this.termLgpdItem.vendor_cpf,
        phone_vendor: this.userData.phone,
        wa_sent: this.termLgpdItem.wa_sent,
        term_status: this.termLgpdItem.term_status,
      }

      this.loadingButtonAdd = true

      await axiosIns.post('api/v1/integration/lgpd_term/store/', body)
        .then(() => {
          this.closeModal()
          this.$swal({
            icon: 'success',
            title: 'Termo incluído.',
            text: 'Operação realizada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.$swal({
            icon: 'error',
            title: 'Erro ao inserir termo!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
        .finally(() => {
          this.loadingButtonAdd = false
        })
      await this.updatedTable()
    },

    async validateTermLgpd() {
      this.loadingButtonValid = true
      await axiosIns
        .get(`/api/v1/integration/lgpd_term/validate/${this.termLgpdItem.id}`)
        .then(res => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'success',
            title: 'Termo validado.',
            text: `${res.data.data.mensage}`,
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'error',
            title: 'Erro ao validar termo!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
        .finally(() => {
          this.loadingButtonValid = false
        })
    },

    async disabledTermLgpd() {
      this.loadingButtonDisable = true
      await axiosIns
        .get(`/api/v1/integration/lgpd_term/disabled/${this.termLgpdItem.id}`)
        .then(res => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'success',
            title: 'Termo inutilizado.',
            text: `${res.data.data.mensage}`,
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'error',
            title: 'Erro ao inutilizar termo!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
        .finally(() => {
          this.loadingButtonDisable = false
        })
    },

    async signAgainTermLgpd() {
      this.loadingButtonSignAgain = true
      await axiosIns
        .get(`/api/v1/integration/lgpd_term/sign_again/${this.termLgpdItem.id}`)
        .then(res => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'success',
            title: 'Termo reaberto.',
            text: `${res.data.data.mensage}`,
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.initialize()
          this.$swal({
            icon: 'error',
            title: 'Erro ao reabrir termo!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
        .finally(() => {
          this.loadingButtonSignAgain = false
        })
    },

    clearModal() {
      this.termLgpdItem.proposal_number = ''

      // this.termLgpdItem.receipt_number = ''
      this.termLgpdItem.client_name = ''

      this.termLgpdItem.cpf_cnpj = ''
      this.termLgpdItem.client_phone = ''
      this.termLgpdItem.contract_date = ''
      this.termLgpdItem.bid_fixed = ''
      this.termLgpdItem.life_insurance = ''
      this.termLgpdItem.customer_subscription = ''
      this.termLgpdItem.submit = ''

      // this.termLgpdItem.email = ''
      this.termLgpdItem.conferred_by = ''
      this.termLgpdItem.observation = ''
      this.termLgpdItem.term_refused = ''
      this.termLgpdItem.code_validity_date = ''
      this.termLgpdItem.term_revalidated = ''
      this.termLgpdItem.validated_date = ''
      this.termLgpdItem.vendor_cpf = ''
      this.termLgpdItem.wa_sent = ''
      this.termLgpdItem.term_status = ''

      this.closeModal()
    },
  },
}
</script>
